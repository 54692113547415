/*

  File: test-card.js
  Kind: Page
  Description: Theme test card to check styling in tailwind etc - should not display in proeuction version

*/

import * as React from "react"
import { graphql }  from "gatsby"

//Components
import Layout from "../components/layout.js"
import { MDXRenderer } from 'gatsby-plugin-mdx';

//Util
import GetFragment from '../components/content/getFragment.js'

const TintChips = (_color) => {
  return Array.from({ length: 10}).map((m, i) => {
    const i_ = i * 100;
    let suffix_ = _color;
    if (i > 0) {
      suffix_ += (`-` + i_);
    }
    return (
      <div key={suffix_} className={`bg-${suffix_} text-${suffix_} h-24`}>OXO</div>
    )
  })
}

const Chips = ['jupiter','saturn','oberon','miranda','titania','neptune'].map(color => {
  return TintChips(color);
});


// markup
const TestCard = ({data}) => {
  const {fragments} = data;
  const Fragments = fragments.nodes 
  return (
    <Layout>

      <MDXRenderer>{GetFragment(Fragments,'testCard')}</MDXRenderer>

      <h1>Color Chips</h1>

      <div className="grid grid-cols-10 grid-rows-6 gap-4">
        {Chips}
      </div>
    </Layout>
  )
}

export default TestCard

export const query = graphql`
  query {
    fragments: allMdx(filter: {fields: {collection: {eq: "fragment"}, locator: {page: {eq: "_testCard"}}}}) {
      nodes {
        body
        fields {
          locator {
            fragment
          }
        }
      }
    }
  }
`
